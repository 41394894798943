import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { Disqus } from 'gatsby-plugin-disqus'
import Sidebar from '../utils/Sidebar'
import Layout from '../components/layout'
import SocialShare from '../components/socialshare'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import PageHeader from '../utils/PageHeader'

export default class Post extends Component {
  render() {
    const { data } = this.props
    const URL = 'https://www.zamanq.com'

    let disqusConfig = {
      url: `${URL}/${data.wordpressPost.slug}`,
      identifier: data.wordpressPost.slug,
      title: data.wordpressPost.title,
    }

    return (
      <Layout>
        <SEO
          title={data.wordpressPost.yoast_meta.yoast_wpseo_title}
          keywords={[
            'headless wordpress',
            'gatsby site',
            'decoupled wordpress',
          ]}
          description={data.wordpressPost.yoast_meta.yoast_wpseo_metadesc}
        />
        <PageHeader
          img={
            data.wordpressPost.featured_media.localFile.childImageSharp.fluid
          }
        >
          <div className="container">
            <h1 className="blog-heading">{data.wordpressPost.title}</h1>
          </div>
        </PageHeader>
        <section style={{ marginTop: '50px' }}>
          <div className="container">
            <div className="row">
              <div className="col s12 m8 l8">
                {data.wordpressPost.featured_media.localFile ? (
                  <div style={{ marginBottom: '20px' }}>
                    <Img
                      fluid={
                        data.wordpressPost.featured_media.localFile
                          .childImageSharp.fluid
                      }
                    />
                  </div>
                ) : null}
                <div className="">
                  {data.wordpressPost.categories.map(category => (
                    <span className="port-cat">{category.name} </span>
                  ))}
                  by{' '}
                  <span className="port-cat">
                    {data.wordpressPost.author.name}
                  </span>
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPost.content,
                  }}
                />
                <hr />
                <div className="row">
                  <div className="col s12 m4 l3">
                    <h6 style={{ padding: '10px 0' }}>Share this post:</h6>
                  </div>{' '}
                  <div className="col s12 m8 l9">
                    <SocialShare
                      title={data.wordpressPost.title}
                      shareUrl={`${URL}/${data.wordpressPost.slug}`}
                    />
                  </div>
                </div>
                <hr />
              </div>
              <div className="col s12 m4 l4 post-sidebar z-depth-1">
                <h5 style={{ marginBottom: '25px' }}>Recent Posts</h5>
                <Sidebar posts={data.allWordpressPost.edges} />
                <h5 style={{ marginTop: '40px' }}>Need a fast website?</h5>
                <Link
                  to="/contact"
                  className="waves-effect waves-light btn-small home-btn"
                  style={{ marginBottom: '25px', marginLeft: '10px' }}
                >
                  Contact Me
                </Link>
              </div>
              <div className="col s12 m8 l8 comments">
                <Disqus config={disqusConfig} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      title
      slug
      link
      content
      categories {
        name
        slug
      }
      author {
        name
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 4) {
      edges {
        node {
          date(formatString: "MMM DD, YYYY")
          title
          excerpt
          slug
          link
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    parallax: file(relativePath: { eq: "1hero.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
