import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Sidebar = ({ posts }) => {
  return (
    <>
      {posts.map(({ node }) => (
        <div className="row valign-wrapper">
          <div className="col s8 m5 l4">
            <Img
              fluid={node.featured_media.localFile.childImageSharp.fluid}
              alt={node.title}
              className="responsive-img"
            />
          </div>
          <div className="col s12 m7 l8">
            <span className="black-text truncate">
              <Link to={node.link}>{node.title}</Link>
            </span>
          </div>
        </div>
      ))}
    </>
  )
}

export default Sidebar
